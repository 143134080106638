

































































































import Vue from 'vue';
import { TransitionExpand } from 'vue-transition-expand';
import 'vue-transition-expand/dist/vue-transition-expand.css';
import gql from 'graphql-tag';
import isEmail from 'validator/lib/isEmail';
// import debounce from 'lodash/debounce';
import loading from '../components/loading.vue';


export default Vue.extend({
  name: 'login-signup',
  components: { TransitionExpand, loading },
  data() {
    return {
      email: '',
      password: '',
      username: '',
      usernameloginwelcome: '',
      errormessage: '' as string,
      errorpasswordinput: false as boolean,
      errorusernameinput: false as boolean,
      erroremailinput: false as boolean,

      loginOrSignupSuccess: false as boolean,
      signupSuccessTickAnimationDelayShow: false as boolean,
      loginSuccessTickAnimationDelayShow: false as boolean,
    };
  },
  computed: {
    signup(): boolean {
      return this.$route.path.indexOf('signup') > -1 ? true : false;
    },
    validEmailLabelError(): boolean {
      const isEmailValid = isEmail(this.email);
      if (this.email === '' || isEmailValid) {
        this.erroremailinput = false;
        return false;
      }
      this.erroremailinput = true;
      return true;
    },
  },
  methods: {
    // uiShowUploadDebouncer: debounce(function(this: any) {
    //   this.uiTotalCompletedSnapshot = this.uiTotalCompletedRunUploadCount;
    //   this.uiShowUpload = true;
    // }, 400),
    signupSuccessTickAnimationDelay() {
      this.loginOrSignupSuccess = true;
      setTimeout(() => {
        this.signupSuccessTickAnimationDelayShow = true;
        this.redirectAfterSuccess();
      }, 500);
    },
    loginSuccessTickAnimationDelay() {
      this.loginOrSignupSuccess = true;
      setTimeout(() => {
        this.loginSuccessTickAnimationDelayShow = true;
        this.redirectAfterSuccess();
      }, 500);
    },
    redirectAfterSuccess() {
      setTimeout(() => {
        if (this.$route.query.redirectFrom && this.$route.query.redirectFrom.length > 0) {
          this.$router.push({path: this.$route.query.redirectFrom as string});
        } else {
          this.$router.push({name: 'sls-home'});
        }
      }, 2500);
    },
    graphQLGateway() {
      if (this.signup) {
        return this.signupGQL();
      }
      return this.loginGQL();
    },
    clearErrors() {
      this.errormessage = '';
      this.errorpasswordinput = false;
      this.erroremailinput = false;
      this.errorusernameinput = false;
    },
    sessionStorageUsername(username: string) {
      sessionStorage.setItem('username', username);
    },
    async loginGQL() {
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`mutation($login: Login!){
            login(login: $login) {
              success
              username
            }
          }`,
          // Parameters
          variables: {
            login: {
              email: this.email,
              password: this.password,
            },
          },
        });
        this.clearErrors();
        this.usernameloginwelcome = res.data.login.username;
        this.loginSuccessTickAnimationDelay();
        this.sessionStorageUsername(res.data.login.username);

      } catch (error) {
        const errorMessage = error.message.replace('GraphQL error: ', '');
        console.log(errorMessage);
        this.errormessage = errorMessage;
        this.errorpasswordinput = true;
        this.erroremailinput = true;
      }
    },
    async signupGQL() {
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`mutation($signup: Signup!){
            signup(signup: $signup) {
              success
            }
          }`,
          // Parameters
          variables: {
            signup: {
              email: this.email,
              password: this.password,
              username: this.username,
            },
          },
        });
        this.clearErrors();
        this.signupSuccessTickAnimationDelay();
        this.sessionStorageUsername(res.data.signup.username);

      } catch (error) {
        const errorMessage = error.message.replace('GraphQL error: ', '');

        // conatins 'network error then edit to server error
        console.log(errorMessage);
        this.errormessage = errorMessage;
        this.errorpasswordinput = true;
        this.erroremailinput = true;
        this.errorusernameinput = true;
      }
    },
  },
});
