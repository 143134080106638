











import Vue from 'vue';
export default Vue.extend({
  name: 'loading',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
    text: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 18,
    },
    padding: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    bubbleSize(): string {
      if (this.size !== 18) {
        const height = `height: ${this.size}px;`;
        const width = `width: ${this.size}px;`;
        return height + width;
      }
      return '';
    },
    bubblePadding(): string {
      if (this.padding !== 2) {
        return `margin: ${this.padding}px;`;
      }
      return '';
    },
    bubbleColor(): string {
      if (this.color !== '#fff') {
        return `background-color: ${this.color};`;
      }
      return '';
    },
    bubbleCSS(): string {
      // var vue = this;
      return this.bubbleColor + this.bubbleSize + this.bubblePadding;
    },
  },
});
